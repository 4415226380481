import { default as _91_46_46_46slug_93bmyQarA1vNMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexFMqyTBVWQAMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addressesba0DS0o4pjMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyjXgxNoE4YqMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contact7MZxGCJYZfMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexGjFn43HYoWMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsLtVCyZpsLwMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93MNjttiPShNMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersvDXf3uL7KOMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billinggoyV62hxJBMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userRgYo2H8gDNMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editnNdMbsnf4TMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexz8HdnlgemUMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newoiWMuhez9xMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as edit8WDMZ970xJMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexNF2eHsX4CcMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profile5VO8nzwpqDMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountiSrtBudlPMMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93ClBeKPpOOaMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as category2HdN3bCTj9Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartcHvwV1FSoPMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentGKFCk4MadjMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summarynWFHZvSM2hMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkout34bImbjdZiMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPassword7Mo7gFhihCMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordRkOmsClrfaMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexwfp0HzlH7yMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexxxszhcOTQ0Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productCeqwM3lRc2Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93cz8TXiE86RMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as indexI76opyHOkUMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registerNee3cS4fBUMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolve9OXR84iEzrMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmsjNFynDoTHdMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeZkmqBDH2ztMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchWj6xxKkfNdMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionORfSNIfesKMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectionJqFyke61T5Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexfxXfQNAQJcMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientxVQloSUHa6Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementEtEl5fsCNtMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElement10i5iIH45GMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionhWOqaAd59nMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderce4nJ8GjZrMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSection9XJIbtQEXHMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugvh6hgNEwL6Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormppHPhJpuDkMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalSpaJOoGgR4Meta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyLV3kq0yQZcMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCta65sj1o10cYMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftZcMkgq1EpHMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightsgYD0byiePMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPagination6kRoLd5OBJMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionSVdgdr9XPqMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesfAsRbKhhMsMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionFtciDI7ntXMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePagination5TywACz4FJMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightD5N7WzjVNbMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as index1y7wnVNMwiMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constants0hcrkbOLNPMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageII82B0YjAkMeta } from "/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageIuxTuvLHxzMeta } from "/home/ecomadm/app/releases/43/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93bmyQarA1vNMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-cms/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountiSrtBudlPMMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account.vue").then(m => m.default || m),
    children: [
  {
    name: addressesba0DS0o4pjMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesba0DS0o4pjMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: companyjXgxNoE4YqMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyjXgxNoE4YqMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/company.vue").then(m => m.default || m)
  },
  {
    name: contact7MZxGCJYZfMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contact7MZxGCJYZfMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: listsLtVCyZpsLwMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsLtVCyZpsLwMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/lists.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: ordersvDXf3uL7KOMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersvDXf3uL7KOMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payments_45and_45billinggoyV62hxJBMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billinggoyV62hxJBMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userRgYo2H8gDNMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editnNdMbsnf4TMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexz8HdnlgemUMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newoiWMuhez9xMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: edit8WDMZ970xJMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexNF2eHsX4CcMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexNF2eHsX4CcMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue").then(m => m.default || m)
  },
  {
    name: profile5VO8nzwpqDMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profile5VO8nzwpqDMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/category.vue").then(m => m.default || m),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93ClBeKPpOOaMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkout34bImbjdZiMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkout34bImbjdZiMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: cartcHvwV1FSoPMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartcHvwV1FSoPMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentGKFCk4MadjMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summarynWFHZvSM2hMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPassword7Mo7gFhihCMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordRkOmsClrfaMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/event/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productCeqwM3lRc2Meta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/product.vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93cz8TXiE86RMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: indexI76opyHOkUMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue").then(m => m.default || m)
  },
  {
    name: registerNee3cS4fBUMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerNee3cS4fBUMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-customer/pages/[locale]/register.vue").then(m => m.default || m)
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolve9OXR84iEzrMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/resolve.vue").then(m => m.default || m)
  },
  {
    name: searchWj6xxKkfNdMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchWj6xxKkfNdMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/search.vue").then(m => m.default || m),
    children: [
  {
    name: cmsjNFynDoTHdMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmsjNFynDoTHdMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/search/cms.vue").then(m => m.default || m)
  },
  {
    name: storeZkmqBDH2ztMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeZkmqBDH2ztMeta || {},
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-ecom/pages/[locale]/search/store.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/43/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/43/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue").then(m => m.default || m)
  }
]